<svelte:head>
	<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css">
	<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap">
	<link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet">
	<link href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.16.0/css/mdb.min.css" rel="stylesheet">
</svelte:head>

<div class="container">
	<h1 class="text-white h6">Tarigu Bdg (Toko Bahan Kue)</h1>
	<div class="flex-center flex-column logo_au">
		<img src="/img/logo.png" class="animated fadeInUp fast" alt="Tarigu Bandung">
	</div>
	<h2 class="text-white h6">Tarigu Bdg (Toko Bahan Kue)</h2>
	<div class="link-list">
		<div class="flex-center flex-column">
			<a href="https://wa.me/6285159586681?text=hi%20tarigu%2Ebdg%2C%20saya%20mau%20pesan%20dong" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/pickup.jpg" width="600" height="219" alt="Tarigu Bdg Pickup">
				</div>
			</a>
			<a href="https://shope.ee/8ezsLzTXPe?share_channel_code=1" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/shopee.jpg" width="600" height="219" alt="Tarigu Bdg Shopee">
				</div>
			</a>
			<a href="https://s.lazada.co.id/l.1NS0" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/lazada.jpg" width="600" height="219" alt="Tarigu Bdg Lazada">
				</div>
			</a>
			<a href="https://www.tiktok.com/@tarigu.bdg" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/tiktok.jpg" width="600" height="219" alt="Tarigu Bdg Tiktok">
				</div>
			</a>
			<a href="https://www.tokopedia.com/tarigubdg" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/tokopedia.jpg" width="600" height="219" alt="Tarigu Bdg Tokopedia">
				</div>
			</a>
			<a href="https://www.blibli.com/merchant/umkm-tarigu-bdg/TAG-70032" target="_blank">
				<div class="card-form animated fadeInDown delay-1s">
					<img src="/img/blibli.jpg" width="600" height="219" alt="Tarigu Bdg BliBli">
				</div>
			</a>
		</div>
	</div>
</div>

<style>
	body {
		background-color: #fff;
		text-align: center;
	}

	.container {
		background-color: white;
		position: relative;
		max-width: 480px;
		width: 100%;
		margin: 0px auto;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}

	.logo_au {
	  	padding: 2rem 0;
	}

	.logo_au img {
	  	width: 50%;
	}

	.link-list a  {
		margin: 0.5rem 0;
	}

	.card-form img {
		background-repeat: no-repeat;
		background-size: 100%;
		width: 100%;
		border-radius: 10px;
		background-position: center;
		transition: .2s all;
	}
	.card-form img:hover {
	  width: 98%;
	}
</style>